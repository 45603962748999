import React, { type FC, useRef, useContext, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import Modal, { type KeyboardOrMouseEvent, ModalFooter } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import AkLozenge from '@atlaskit/lozenge';
import CheckCircleIcon from '@atlaskit/icon/core/success';
import type { MessageDescriptor } from '@atlaskit/editor-plugin-table/types';

import type { DialogsContainerProps } from '@confluence/dialogs';
import {
	ExperienceTrackerContext,
	SUBMIT_GUEST_REQUEST_TO_UPGRADE_EXPERIENCE,
} from '@confluence/experience-tracker';

import GuestAvatar from '../img/Guest-Avatar.svg';
import TeamAvatars from '../img/Team-Avatars.svg';

import { GuestRequestUpgradeButton } from './GuestRequestUpgradeButton';
import { useSubmitGuestUpgradeRequest } from './useSubmitGuestUpgradeRequest';

const i18n = defineMessages({
	guestRequestToUpgradeLearnMoreModalTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-title',
		defaultMessage: 'Request licensed user access to join your teammates',
		description: 'The modal title for the learn more modal',
	},
	guestRequestToUpgradeLearnMoreModalTitleSpacesMenu: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-title-spaces-menu',
		defaultMessage: 'Request full access',
		description: 'The modal title for the learn more modal when opened from the spaces menu',
	},
	guestRequestToUpgradeLearnMoreModalCopy: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-copy',
		defaultMessage:
			'You are a guest on this Confluence site. Request licensed user access to unlock new features, and an admin will review your request.',
		description: 'The modal copy directly beneath the title',
	},
	guestRequestToUpgradeLearnMoreModalCopySpacesMenu: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-copy-spaces-menu',
		defaultMessage:
			'As a guest, you have limited access to this Confluence site but can request full access to unlock new ways to collaborate.',
		description: 'The modal copy directly beneath the title when opened from the spaces menu',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-title',
		defaultMessage: 'Guest',
		description: 'The title of the Guest comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardTitleLozenge: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-title-lozenge',
		defaultMessage: 'YOU',
		description: 'The lozenge in the title of the Guest comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardCopy: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-copy',
		defaultMessage:
			'Your admin may further restrict your functionality from these default settings.',
		description: 'The main copy of the Guest comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardCopySpacesMenu: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-copy-spaces-menu',
		defaultMessage: 'Your access is limited to:',
		description:
			'The main copy of the Guest comparison card in the Learn More modal when opened from the spaces menu.',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardBenefitRowOne: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-benefit-row-one',
		defaultMessage: 'View, create, and edit in this space',
		description: 'The first benefit row of the Guest comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalGuestCardBenefitRowTwo: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-guest-card-benefit-row-two',
		defaultMessage: 'Leave comments in this space',
		description: 'The second benefit row of the Guest comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-title',
		defaultMessage: 'Licensed user',
		description: 'The title of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardTitleSpacesMenu: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-title-spaces-menu',
		defaultMessage: 'Full access',
		description:
			'The title of the Licensed User comparison card in the Learn More modal when opened from the spaces menu. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardCopy: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-copy',
		defaultMessage: 'Everything in guest plus,',
		description: 'The main copy of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardCopySpacesMenu: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-copy-spaces-menu',
		defaultMessage: 'Unlocks:',
		description: 'The main copy of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowOne: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-benefit-row-one',
		defaultMessage: 'Create your own space',
		description:
			'The first benefit row of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowTwo: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-benefit-row-two',
		defaultMessage: 'View and collaborate in other spaces',
		description:
			'The second benefit row of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowThree: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-benefit-row-three',
		defaultMessage: 'See other people’s profiles',
		description:
			'The third benefit row of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowFour: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-benefit-row-four',
		defaultMessage: 'Mention other people',
		description:
			'The fourth benefit row of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowFive: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-licensed-user-card-benefit-row-five',
		defaultMessage: 'Create and join teams',
		description:
			'The fifth benefit row of the Licensed User comparison card in the Learn More modal. ',
	},
	guestRequestToUpgradeLearnMoreModalFooterSecondaryButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-footer-secondary-button',
		defaultMessage: 'Maybe later',
		description: 'The secondary button in the Learn More modal footer.',
	},
	guestRequestToUpgradeLearnMoreModalFooterSecondaryButtonWhenPrimaryDisabled: {
		id: 'external-collab-ui.guest-request-to-upgrade-learn-more-modal-footer-secondary-button-when-primary-disabled',
		defaultMessage: 'Close',
		description:
			'The secondary button in the Learn More modal footer, when the primary is disabled',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeader = styled.div({
	width: '100%',
	boxSizing: 'border-box',
	textAlign: 'center',
	padding: token('space.300'),
	paddingTop: token('space.400'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalBody = styled.div({
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalCopy = styled.div({
	maxWidth: '520px',
	boxSizing: 'border-box',
	margin: `${token('space.0')} auto ${token('space.300')} auto`,
	textAlign: 'center',
	padding: `${token('space.0')} ${token('space.300')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValuePropCardContainer = styled.div({
	display: 'flex',
	flexWrap: 'nowrap',
	gap: token('space.300'),
	padding: `${token('space.0')} ${token('space.300')}`,
	marginBottom: token('space.025'),

	// Mobile
	'@media (max-width: 487px)': {
		flexWrap: 'wrap',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValuePropCard = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'wrap',
	alignItems: 'flex-start',
	border: `1px solid ${token('color.border.input')}`,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage
	borderRadius: token('border.radius.300', '3px'),
	flex: '1 1 340px',
	minHeight: '344px',
	boxShadow: token('elevation.shadow.raised'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardTitleImage = styled.img({
	width: '100%',
	height: token('space.800'),
	margin: `${token('space.300')} auto 0 auto`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardHeader = styled.h1({
	font: token('font.heading.medium'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: token('space.100'),
	width: '100%',
	boxSizing: 'border-box',
	textAlign: 'center',
	padding: `${token('space.250')} ${token('space.300')}`,
	marginTop: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContent = styled.div({
	padding: `${token('space.0')} ${token('space.300')}`,
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardCopy = styled.div({
	marginBottom: token('space.250'),
	boxSizing: 'border-box',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BenefitRowContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: token('space.100'),
	marginBottom: token('space.100'),
});

type Benefit = {
	message: MessageDescriptor;
	highlighted?: boolean;
};

const GUEST_BENEFITS: Benefit[] = [
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalGuestCardBenefitRowOne,
	},
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalGuestCardBenefitRowTwo,
	},
];

const USER_BENEFITS: Benefit[] = [
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowOne,
		highlighted: true,
	},
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowTwo,
		highlighted: true,
	},
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowThree,
		highlighted: true,
	},
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowFour,
		highlighted: true,
	},
	{
		message: i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardBenefitRowFive,
		highlighted: true,
	},
];

const BenefitRow = ({
	highlighted = false,
	children,
}: React.PropsWithChildren<{ highlighted?: boolean }>) => {
	return (
		<BenefitRowContainer>
			<CheckCircleIcon
				color={highlighted ? token('color.icon.success') : token('color.icon.accent.gray')}
				spacing="none"
				label=""
			/>
			{children}
		</BenefitRowContainer>
	);
};

const determineCloseTrigger = (
	event: KeyboardOrMouseEvent,
	analyticsEvent: UIAnalyticsEvent,
): string => {
	return analyticsEvent.payload.actionSubject === 'button'
		? 'closeButton'
		: analyticsEvent.payload.actionSubject === 'modalDialog'
			? event instanceof KeyboardEvent && event.key === 'Escape'
				? 'escapeKey'
				: 'outsideClick'
			: 'unknown';
};

export interface GuestRequestToUpgradeLearnMoreModalProps {
	onSubmit: (error?: Error) => void;
	requestPending: boolean;
	closeModal: () => void;
	analytics?: {
		touchpointId?: string;
	};
}

export const GuestRequestToUpgradeLearnMoreModal: FC<GuestRequestToUpgradeLearnMoreModalProps> = ({
	onSubmit,
	requestPending,
	closeModal,
	analytics,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const headerRef = useRef<HTMLDivElement>(null);

	const [submitGuestUpgradeRequest, { loading }] = useSubmitGuestUpgradeRequest({
		onCompleted: onSubmit,
	});

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'guestRequestAccessValuePropModal',
				attributes: {
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, analytics]);

	const scrollToTop = () => headerRef.current?.scrollIntoView({ behavior: 'smooth' });

	const submitGuestUpgradeRequestWithAnalytics = async (
		_: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				...analyticsEvent.payload,
				actionSubjectId: 'submitGuestUpgradeRequest',
				source: 'guestRequestAccessValuePropModal',
				attributes: {
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
		experienceTracker.start({
			name: SUBMIT_GUEST_REQUEST_TO_UPGRADE_EXPERIENCE,
		});
		await submitGuestUpgradeRequest();
		closeModal();
	};

	const closeModalWithAnalytics = (
		event: KeyboardOrMouseEvent,
		analyticsEvent: UIAnalyticsEvent,
	): void => {
		closeModal();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'closed',
				actionSubject: 'modalDialog',
				actionSubjectId: 'guestRequestAccessValuePropModal',
				source: 'guestRequestAccessValuePropModal',
				attributes: {
					requested: requestPending,
					closeTrigger: determineCloseTrigger(event, analyticsEvent),
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
	};

	const secondaryButtonCopy = requestPending
		? i18n.guestRequestToUpgradeLearnMoreModalFooterSecondaryButtonWhenPrimaryDisabled
		: i18n.guestRequestToUpgradeLearnMoreModalFooterSecondaryButton;

	return (
		<Modal
			width={752}
			onClose={closeModalWithAnalytics}
			onOpenComplete={scrollToTop}
			shouldScrollInViewport
			shouldCloseOnEscapePress
			shouldCloseOnOverlayClick
		>
			<ModalHeader ref={headerRef}>
				<Heading size="large" as="h1">
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalTitle} />
				</Heading>
			</ModalHeader>
			<ModalBody>
				<ModalCopy>
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalCopy} />
				</ModalCopy>
				<ValuePropCardContainer>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<ValuePropCard className="first">
						<CardTitleImage src={GuestAvatar} />
						<CardHeader>
							<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitle} />
							<AkLozenge>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitleLozenge}
								/>
							</AkLozenge>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalGuestCardCopy} />
							</CardCopy>
							{GUEST_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
					<ValuePropCard>
						<CardTitleImage src={TeamAvatars} />
						<CardHeader>
							<FormattedMessage
								{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardTitle}
							/>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardCopy}
								/>
							</CardCopy>
							{USER_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
				</ValuePropCardContainer>
			</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={closeModalWithAnalytics}>
					<FormattedMessage {...secondaryButtonCopy} />
				</Button>
				<GuestRequestUpgradeButton
					appearance="primary"
					requestPending={requestPending}
					loading={loading}
					onClick={submitGuestUpgradeRequestWithAnalytics}
				/>
			</ModalFooter>
		</Modal>
	);
};

export type GuestRequestToUpgradeLearnMoreModalDialogProps = DialogsContainerProps & {
	onSubmit: (error?: Error) => void;
	requestPending: boolean;
	analytics?: {
		touchpointId?: string;
	};
	isSpacesMenu?: boolean;
};

export const GuestRequestToUpgradeLearnMoreDialogModal = ({
	onClose,
	onCloseComplete,
	onSubmit,
	requestPending,
	analytics,
	isSpacesMenu = false,
}: GuestRequestToUpgradeLearnMoreModalDialogProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const headerRef = useRef<HTMLDivElement>(null);

	const [submitGuestUpgradeRequest, { loading }] = useSubmitGuestUpgradeRequest({
		onCompleted: onSubmit,
	});

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'guestRequestAccessValuePropModal',
				attributes: {
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, analytics]);

	const scrollToTop = () => headerRef.current?.scrollIntoView({ behavior: 'smooth' });

	const submitGuestUpgradeRequestWithAnalytics = async (
		_: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				...analyticsEvent.payload,
				actionSubjectId: 'submitGuestUpgradeRequest',
				source: 'guestRequestAccessValuePropModal',
				attributes: {
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
		experienceTracker.start({
			name: SUBMIT_GUEST_REQUEST_TO_UPGRADE_EXPERIENCE,
		});
		await submitGuestUpgradeRequest();
		onClose();
	};

	const closeModalWithAnalytics = (
		event: KeyboardOrMouseEvent,
		analyticsEvent: UIAnalyticsEvent,
	): void => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'closed',
				actionSubject: 'modalDialog',
				actionSubjectId: 'guestRequestAccessValuePropModal',
				source: 'guestRequestAccessValuePropModal',
				attributes: {
					requested: requestPending,
					closeTrigger: determineCloseTrigger(event, analyticsEvent),
					touchpointId: analytics?.touchpointId,
				},
			},
		}).fire();
		onClose();
	};

	const secondaryButtonCopy = requestPending
		? i18n.guestRequestToUpgradeLearnMoreModalFooterSecondaryButtonWhenPrimaryDisabled
		: i18n.guestRequestToUpgradeLearnMoreModalFooterSecondaryButton;

	return isSpacesMenu ? (
		<Modal
			width={752}
			onClose={closeModalWithAnalytics}
			onCloseComplete={onCloseComplete}
			onOpenComplete={scrollToTop}
			shouldScrollInViewport
			shouldCloseOnEscapePress
			shouldCloseOnOverlayClick
		>
			<ModalHeader ref={headerRef}>
				<Heading size="large" as="h1">
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalTitleSpacesMenu} />
				</Heading>
			</ModalHeader>
			<ModalBody>
				<ModalCopy>
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalCopySpacesMenu} />
				</ModalCopy>
				<ValuePropCardContainer>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<ValuePropCard className="first">
						<CardTitleImage src={GuestAvatar} />
						<CardHeader>
							<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitle} />
							<AkLozenge>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitleLozenge}
								/>
							</AkLozenge>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalGuestCardCopySpacesMenu}
								/>
							</CardCopy>
							{GUEST_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
					<ValuePropCard>
						<CardTitleImage src={TeamAvatars} />
						<CardHeader>
							<FormattedMessage
								{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardTitleSpacesMenu}
							/>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardCopySpacesMenu}
								/>
							</CardCopy>
							{USER_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
				</ValuePropCardContainer>
			</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={closeModalWithAnalytics}>
					<FormattedMessage {...secondaryButtonCopy} />
				</Button>
				<GuestRequestUpgradeButton
					appearance="primary"
					requestPending={requestPending}
					loading={loading}
					onClick={submitGuestUpgradeRequestWithAnalytics}
				/>
			</ModalFooter>
		</Modal>
	) : (
		<Modal
			width={752}
			onClose={closeModalWithAnalytics}
			onCloseComplete={onCloseComplete}
			onOpenComplete={scrollToTop}
			shouldScrollInViewport
			shouldCloseOnEscapePress
			shouldCloseOnOverlayClick
		>
			<ModalHeader ref={headerRef}>
				<Heading size="large" as="h1">
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalTitle} />
				</Heading>
			</ModalHeader>
			<ModalBody>
				<ModalCopy>
					<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalCopy} />
				</ModalCopy>
				<ValuePropCardContainer>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<ValuePropCard className="first">
						<CardTitleImage src={GuestAvatar} />
						<CardHeader>
							<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitle} />
							<AkLozenge>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalGuestCardTitleLozenge}
								/>
							</AkLozenge>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage {...i18n.guestRequestToUpgradeLearnMoreModalGuestCardCopy} />
							</CardCopy>
							{GUEST_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
					<ValuePropCard>
						<CardTitleImage src={TeamAvatars} />
						<CardHeader>
							<FormattedMessage
								{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardTitle}
							/>
						</CardHeader>
						<CardContent>
							<CardCopy>
								<FormattedMessage
									{...i18n.guestRequestToUpgradeLearnMoreModalLicensedUserCardCopy}
								/>
							</CardCopy>
							{USER_BENEFITS.map((benefit) => (
								<BenefitRow key={benefit.message.id} highlighted={benefit.highlighted}>
									<FormattedMessage {...benefit.message} />
								</BenefitRow>
							))}
						</CardContent>
					</ValuePropCard>
				</ValuePropCardContainer>
			</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={closeModalWithAnalytics}>
					<FormattedMessage {...secondaryButtonCopy} />
				</Button>
				<GuestRequestUpgradeButton
					appearance="primary"
					requestPending={requestPending}
					loading={loading}
					onClick={submitGuestUpgradeRequestWithAnalytics}
				/>
			</ModalFooter>
		</Modal>
	);
};

/**
 * This factory is necessary, because the ProfileMenu uses a special setup to show modals
 * We are exporting it from here so that we can test that the consumer code is calling
 * this exact factory when trying to show the Learn More modal.
 * */
export const GuestRequestToUpgradeLearnMoreModalFactory =
	(args: Omit<GuestRequestToUpgradeLearnMoreModalProps, 'closeModal'>) =>
	(closeModal: () => void) => (
		<GuestRequestToUpgradeLearnMoreModal {...args} closeModal={closeModal} />
	);
